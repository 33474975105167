import React, { useState } from "react";
import { useEffect } from "react";
import { Card, YoutubeCard } from "../../components/card";
import { FormError } from "../../components/Error";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import emailjs from "@emailjs/browser";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { FaTwitterSquare } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Navbar } from "../../components/nav/Navbar";
import AOS from "aos";

const Home = () => {
  const handleMouseMove = (event) => {
    // 👇 Get mouse position relative to element

    const mouse_x = event.clientX;
    const mouse_y = event.clientY;

    var fl = document.getElementById("flashlight");
    fl.style.transform =
      "translate(calc(" +
      mouse_x +
      "px - 50vw), " +
      "calc(" +
      mouse_y +
      "px - 50vh))";
  };

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: yup.object({
      email: yup.string().email().required("Email is required"),
      subject: yup.string().required("Subject is required"),
      message: yup.string().required("Email message is required"),
    }),

    onSubmit: (prop) => {
      let templateParams = prop;
      templateParams.to_name = "Remigius";
      templateParams.from_name = prop.email;

      setLoading(true);

      emailjs
        .send(
          "service_stlrfhk",
          "template_ri7zico",
          templateParams,
          "23onJtMeeTiF8Y7GB"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);

            toast.success("Email sent successfully", {
              duration: 3000,
            });

            formik.setValues((values) => ({
              ...values,
              email: "",
              subject: "",
              message: "",
            }));
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );

      // dispatch(loginRequest(prop));

      setLoading(false);
    },
  });

  return (
    <div>
      <Navbar />
      <section className="h-[40vh] lg:h-[80vh] relative bg-primary flex flex-col justify-between overflow-hidden">
        <video
          className="w-full h-full object-cover absolute hidden lg:block"
          preload="metadata"
          id="promo-video"
          autoPlay={true}
          poster="/videos/promo-poster.png"
          loop="loop"
          muted="muted"
        >
          <source
            src="/video/reels.mp4"
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          />
          The video tag is not supported by your browser :(
        </video>

        <iframe
          className="w-full h-full object-cover absolute block lg:hidden"
          src="https://www.youtube.com/embed/wXinUDqOZGY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>

        <div className="container mx-auto px-4 py-8 flex flex-col   lg:flex-row  justify-between relative mt-auto">
          <h4 className="font-bodyRegular font-bold text-sm md:text-base text-white">
            Owning The Narrative
          </h4>
          <div className=" px-4 bg-black  w-[200px] rounded-xl flex items-center justify-center gap-x-6 text-white text-2xl md:text-3xl">
            <a
              className="hover:scale-105 transition-all duration-500"
              href={"https://www.instagram.com/afrochives/"}
              target="_blank"
            >
              {" "}
              <AiFillInstagram />
            </a>
            <a
              className="hover:scale-105 transition-all duration-500"
              href={"https://twitter.com/afrochives"}
              target="_blank"
            >
              {" "}
              <FaTwitterSquare />
            </a>
            <a
              className="hover:scale-105 transition-all duration-500"
              href={"https://www.youtube.com/@afrochives"}
              target="_blank"
            >
              {" "}
              <AiFillYoutube />
            </a>
            <a className="hover:scale-105 transition-all duration-500">
              <IoMdMail />
            </a>
          </div>
        </div>
      </section>

      <div className="relative">
        <img src="/img/pattern/pattern.svg"></img>
      </div>
      <section
        id="about"
        className="h-[70vh] lg:h-[100vh] py-32 lg:py-48 relative overflow-hidden"
      >
        <div className="absolute grid grid-cols-2 md:grid-cols-4 top-0 left-0">
          <img className="" src="/img/bg/glowbg1.png" />
          <img className="" src="/img/bg/glowbg1.png" />
          <img className="" src="/img/bg/glowbg1.png" />
          <img className="" src="/img/bg/glowbg1.png" />
          <img className="" src="/img/bg/glowbg1.png" />
          <img className="" src="/img/bg/glowbg1.png" />
          <img className="" src="/img/bg/glowbg1.png" />
          <img className="" src="/img/bg/glowbg1.png" />
        </div>

        <div onMouseMove={(e) => handleMouseMove(e)} id="flashlight"></div>

        <div className="container  lg:h-[70vh] mx-auto relative flex flex-col gap-y-4 items-center justify-between">
          <div>
            <h3 className="font-head text-white text-[60px] lg:text-[170px] text-center">
              Who we are
            </h3>
            <p className="font-bodyLight lg:-mt-10 text-sm  lg:text-lg text-white text-md text-center mx-auto max-w-3xl">
              Afrochives Studio is a media and content studio. Our mission is 
              to inform, educate, entertain and inspire audiences around the
              world through Afro-centric stories.
            </p>
          </div>

          <img className="w-12 lg:mt-auto" src="/img/icon/mask.svg" />
        </div>
      </section>

      <section>
        <div className="container mx-auto p-2 bg-[#D9D9D9] mt-10 rounded-lg flex flex-col gap-y-2">
          <div className="flex flex-col gap-y-3 lg:flex-row  gap-x-6 py-5 lg:py-3 p-3 bg-[#FBF8F8] rounded-lg">
            <div className="self-center items-center lg:max-w-[200px]">
              <div className=" hidden lg:block">
                <h3 className="font-head text-5xl lg:text-6xl text-center mx-auto">
                  OUR
                </h3>
                <h3 className="font-head text-5xl lg:text-6xl text-center mx-auto">
                  WORK
                </h3>
              </div>

              <div className="block lg:hidden">
                <h3 className="font-head text-5xl lg:text-6xl text-center mx-auto">
                  OUR WORK
                </h3>
              </div>
              <p className="font-bodyLight text-xs mt-4 text-center lg:text-right">
                Afrochives Studio features the experience and voices of people
                of African origin and descent. We tell original and compelling
                stories for a global audience through premium original shows,
                series, and digital multimedia.
              </p>
            </div>
            <div className="overflow-x-scroll rounded-lg flex gap-x-3 gap-y-4">
              <YoutubeCard
                videosrc={"https://www.youtube.com/embed/IoV36M6NHBE"}
              />
              <YoutubeCard
                videosrc={"https://www.youtube.com/embed/-DecH5mejPU"}
              />
              <YoutubeCard
                videosrc={"https://www.youtube.com/embed/NnmqF_rn5h0"}
              />
              <YoutubeCard
                videosrc={"https://www.youtube.com/embed/sH-UDp3kbXc"}
              />
            </div>
          </div>
          <div className="flex flex-col-reverse gap-y-3 lg:flex-row  gap-x-6 py-5 lg:py-3 p-3 bg-[#D9ECF4] rounded-lg">
            <div className="overflow-x-scroll rounded-lg flex gap-x-3 gap-y-4">
              <YoutubeCard
                videosrc={"https://www.youtube.com/embed/I-LUEED_njo"}
              />
              <YoutubeCard
                videosrc={"https://www.youtube.com/embed/7JGm83eGauc"}
              />
              <YoutubeCard
                videosrc={"https://www.youtube.com/embed/Pd1HVp8eD2U"}
              />
              <YoutubeCard
                videosrc={"https://www.youtube.com/embed/0SZ016XLcEk"}
              />
            </div>

            <div className="self-center items-center lg:max-w-[200px]">
              <div className=" hidden lg:block">
                <h3 className="font-head text-5xl lg:text-6xl text-center mx-auto uppercase">
                  AFROCHIVE
                </h3>
                <h3 className="font-head text-5xl lg:text-6xl text-center mx-auto uppercase">
                  Junior
                </h3>
              </div>

              <div className="block lg:hidden">
                <h3 className="font-head text-5xl lg:text-6xl text-center mx-auto uppercase">
                  Afrochives Junior
                </h3>
              </div>

              <p className="font-bodyLight text-xs text-center lg:text-left mt-4">
                Afrochives Junior is home to the best entertainment for
                children. We bring an original and entertaining blend of
                animations and live action segments to unlock imagination and
                curiosity.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-12 lg:pt-20">
        <div className="container mx-auto text-center px-4">
          <h3 className="font-head text-5xl lg:text-7xl text-center">NEWS</h3>
          <div className=" grid grid-cols-1 lg:grid-cols-3 gap-x-4 gap-y-4 max-w-6xl mx-auto">
            <Card
              head={"PRESS RELEASE."}
              body={
                "Aspiring Filmmakers Wanted: Afrochives Studio Opens Applications for Cohort 1 of Okike Lab, a Transformative Creative Programme.... "
              }
              href={"https://medium.com/@afrochives/press-release-81c475176bc6"}
              imgsrc={"/img/news/news4.jpg"}
            />
            <Card
              head={"Casting Call | Afrochives Studio."}
              body={
                "PRODUCTION DESCRIPTION: Casting a paranormal-horror film about some teenage girls who unwittingly encounter a paranormal entity during one of their juvenile escapades... "
              }
              href={
                "https://medium.com/@afrochives/casting-call-afrochives-studio-d8e1274ec704"
              }
              imgsrc={"/img/news/news5.jpg"}
            />
            <Card
              head={"Introducing Afrochives Studio..."}
              body={
                "We are thrilled to announce the launch of Afrochives Studio, a dynamic media and content studio dedicated to informing, educating, entertaining, and inspiring audiences worldwide... "
              }
              href={
                "https://medium.com/@afrochives/introducing-afrochives-studio-amplifying-afro-centric-stories-for-global-audiences-900c42981bcc"
              }
              imgsrc={"/img/news/news3.png"}
            />
            {/* <Card
              head={"Afrochives Studio partners..."}
              body={
                "In an exciting collaboration that is set to captivate audiences worldwide, Afrochives Studio and Crater Library & Publishers have announced a groundbreaking partnership for... "
              }
              href={
                "https://medium.com/@afrochives/afrochives-studio-partners-with-crater-library-publishers-for-the-animation-production-of-their-238a296b6d96"
              }
              imgsrc={"/img/news/news1.jpg"}
            />
            <Card
              head={"Afrochives Studio and Flocker..."}
              body={
                "Flocker Records, an Independent Record Label specializing in recording, shooting, and production of various media content, has announced a strategic partnership with... "
              }
              href={
                "https://medium.com/@afrochives/afrochives-studio-and-flocker-records-partners-for-latino-perricos-lost-boy-album-release-b595d41288cb"
              }
              imgsrc={"/img/news/news2.png"}
            /> */}
          </div>

          <a
            href="https://medium.com/@afrochives"
            target="_blank"
            className="bg-black w-[150px] mx-auto text-white rounded-lg flex items-center justify-center py-3 lg:py-3 text-xs lg:text-sm font-bodyRegular mt-10 hover:bg-white hover:border hover:border-black hover:text-black transition-all duration-500"
          >
            Read more
          </a>
        </div>
      </section>

      <section className="pt-20 lg:py-32">
        <div className="container mx-auto text-center px-4">
          <h3 className="font-head text-4xl lg:text-7xl text-center">
            CONTACT US
          </h3>
          <div data-aos="fade-up" className="max-w-xl mx-auto">
            <div className="w-full mt-3">
              <input
                className="border border-[#868585] rounded font-bodyRegular text-xs bg-[#F4F3F3] w-full py-3 px-4 focus:outline-none "
                type={"text"}
                {...formik.getFieldProps("email")}
                placeholder="Enter your mail"
                required
              />
              {formik.errors.email && formik.touched.email && (
                <FormError message={formik.errors.email} />
              )}
            </div>
            <div className="w-full mt-3">
              <input
                className="border border-[#868585] rounded font-bodyRegular text-xs bg-[#F4F3F3] w-full py-3 px-4 focus:outline-none "
                type={"text"}
                {...formik.getFieldProps("subject")}
                placeholder="Enter mail subject"
                required
              />
              {formik.errors.subject && formik.touched.subject && (
                <FormError message={formik.errors.subject} />
              )}
            </div>
            <div className="w-full mt-2 ">
              <textarea
                className="border border-[#868585] h-[100px] rounded font-bodyRegular text-xs bg-[#F4F3F3] w-full py-5 px-4 focus:outline-none "
                type={"text"}
                {...formik.getFieldProps("message")}
                placeholder="Enter mail message"
                required
              />
              {formik.errors.message && formik.touched.message && (
                <FormError message={formik.errors.message} />
              )}
            </div>

            <div
              onClick={formik.handleSubmit}
              className="bg-black text-white rounded-lg flex items-center justify-center py-3 text-sm font-bodyRegular mt-4 hover:bg-white hover:border hover:border-black hover:text-black transition-all duration-500"
            >
              {loading ? (
                <CircularProgress size={20} color="white" />
              ) : (
                "Submit"
              )}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mx-auto py-8 px-4 flex flex-col lg:flex-row justify-between gap-y-4">
          <h4 className="font-bodyRegular text-xs">
            Powered by Greysoft Solutions LTD
          </h4>
          <h4 className="font-bodyRegular text-xs max-w-xl">
            © 2023 Afrochives. All rights reserved. info@afrochives.studio. Art
            Work by Oji Chiagoziem Nneamaka.
          </h4>

          <div className=" flex gap-x-6 text-black text-3xl">
            <a
              className="hover:scale-105 transition-all duration-500"
              href={"https://www.instagram.com/afrochives/"}
              target="_blank"
            >
              {" "}
              <AiFillInstagram />
            </a>
            <a
              className="hover:scale-105 transition-all duration-500"
              href={"https://twitter.com/afrochives"}
              target="_blank"
            >
              {" "}
              <FaTwitterSquare />
            </a>
            <a
              className="hover:scale-105 transition-all duration-500"
              href={"https://www.youtube.com/@afrochives"}
              target="_blank"
            >
              {" "}
              <AiFillYoutube />
            </a>

            <a>
              <IoMdMail />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
