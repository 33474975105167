import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import { PrivateRoute } from "../components/PrivateRoute";
import Home from "./Home";

const Pages = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
    </Switch>
  );
};

export default Pages;
