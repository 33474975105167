import React, { useState } from "react";
import SwipeableDrawer from "@material-ui/core/Drawer";
import { HiMenuAlt1 } from "react-icons/hi";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

const smoothScroll = (page) => {
  window.scroll({
    top: window.innerHeight * page,
    left: 0,
    behavior: "smooth",
  });
};

export const Navbar = () => {
  const [sideNav, setsideNav] = useState(false);

  return (
    <>
      <section className="flex-col justify-between bg-black hidden lg:flex ">
        <nav className="container mx-auto py-4 flex justify-between items-center relative  text-white">
          <img className="w-[150px]" src="/img/logo/logo1.png" />
          <div
            onClick={() => smoothScroll(1)}
            className="font-bodyRegular uppercase font-bold text-base cursor-pointer hover:scale-105 transition-all duration-500"
          >
            About Us
          </div>
          <div
            onClick={() => smoothScroll(2)}
            className="font-bodyRegular uppercase font-bold text-base cursor-pointer hover:scale-105 transition-all duration-500"
          >
            Our Work
          </div>
          <div
            onClick={() => smoothScroll(3)}
            className="font-bodyRegular uppercase font-bold text-base cursor-pointer hover:scale-105 transition-all duration-500"
          >
            News
          </div>
          <div
            onClick={() => smoothScroll(4)}
            className="font-bodyRegular uppercase font-bold text-base cursor-pointer hover:scale-105 transition-all duration-500"
          >
            Contact Us
          </div>
        </nav>
      </section>

      <section className="flex justify-between items-center relative z-20 px-4 py-2 w-full bg-white lg:hidden">
        <div
          className="text-2xl cursor-pointer text-black lg:hidden "
          onClick={() => setsideNav(!sideNav)}
        >
          <HiMenuAlt1 className="cursor-pointer" />

          <SwipeableDrawer
            anchor={"left"}
            open={sideNav}
            onClose={() => setsideNav(false)}
            onOpen={() => setsideNav(true)}
          >
            <div className="bg-darkGray h-screen w-screen text-lightGray relative">
              <div
                onClick={() => setsideNav(!sideNav)}
                className="ml-auto text-2xl text-black absolute right-3 top-3 cursor-pointer"
              >
                <AiOutlineClose />
              </div>

              <div className="flex flex-col items-center justify-center h-full pt-8 gap-4 pl-5">
                <div className="font-bodyRegular uppercase font-bold text-sm cursor-pointer">
                  About Us
                </div>
                <div
                  onClick={() => smoothScroll(1.3)}
                  className="font-bodyRegular uppercase font-bold text-sm cursor-pointer"
                >
                  Our Work
                </div>
                <div
                  onClick={() => smoothScroll(2.4)}
                  className="font-bodyRegular uppercase font-bold text-sm cursor-pointer"
                >
                  News
                </div>
                <div
                  onClick={() => smoothScroll(4.2)}
                  className="font-bodyRegular uppercase font-bold text-sm cursor-pointer"
                >
                  Contact Us
                </div>
                {/* <a 
                  href="/faq"
                  onClick={() => setsideNav(false)}
                  className="font-heading text-sm text-gray-600 cursor-pointer"
                >
                  FAQ
                </div> */}
              </div>
            </div>
          </SwipeableDrawer>
        </div>

        <div className="col-span-7 text-center">
          <div className="mx-auto z-40 flex w-full justify-between">
            <img src="/img/logo/logo.png" className="h-[40px]" />
          </div>
        </div>
      </section>
    </>
  );
};
