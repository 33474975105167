import React from "react";

export const Card = ({ head, body, imgsrc, href }) => {
  return (
    <a
      href={href}
      target="_blank"
      className="relative rounded border bg-[#F4F3F3]"
      data-aos="fade-up"
    >
      <img
        className="rounded h-[200px] w-full object-cover border-b"
        src={imgsrc}
      />
      <div className="flex flex-col text-left gap-y-4 p-4 pb-8">
        <h3 className="text-md lg:text-xl font-bodyRegular"> {head}</h3>
        <p className="font-bodyLight text-xs">{body}</p>
      </div>
      <img className="absolute bottom-0" src="/img/pattern/cardpattern.svg" />
    </a>
  );
};

export const YoutubeCard = ({ videosrc }) => {
  return (
    <div className="bg-black rounded-lg">
      <iframe
        className="w-[250px] lg:w-[360px] rounded-lg lg:h-[315px]"
        src={videosrc}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>

      {/* <div className="grid lg:grid-cols-2 px-4">
        <div className="flex flex-col gap-y-4 pb-4">
          <h3 className="text-white text-3xl lg:text-6xl font-bodyRegular">
            {" "}
            STORY OF ULI
          </h3>
          <p className="font-bodyLight text-xs text-white">
            Afrochives Studio is a media and content studio. Our mission is  to
            inform, educate, entertain and inspire audiences around the world
            through Afro-centric stories.
          </p>
        </div>
      </div> */}
    </div>
  );
};
