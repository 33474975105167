import React from "react";
import { BiErrorCircle } from "react-icons/bi";

export const FormError = ({ message }) => {
  return (
    <div className="flex items-center mt-2 text-red-500 text-xs font-body font-light transition-all duration-500">
      <BiErrorCircle className="mr-2 h-4" />
      <p>{message}</p>
    </div>
  );
};
